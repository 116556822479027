import Vue from 'vue';
import VueRouter from 'vue-router'; // public routes

import Login from '@/views/public/Login.vue';
import Video from '@/views/public/Video.vue';
import AppRoot from '@/views/app/AppRoot.vue';
import Home from '@/views/app/Home.vue';
import Job from '@/views/app/Job.vue';
import Form from '@/views/app/Form.vue';
import { authCheck } from '@/scripts/session.js';
var needAuth = true;
Vue.use(VueRouter);
var routes = [];
routes.push({
  path: '/video',
  name: 'video',
  meta: {
    needAuth: needAuth
  },
  component: Video
});
routes.push({
  path: '/login',
  name: 'login',
  component: Login
});
var app = {
  path: '/',
  name: 'root',
  component: AppRoot,
  meta: {
    needAuth: needAuth
  },
  children: []
};
app.children.push({
  path: '/',
  name: 'home',
  component: Home,
  meta: {
    needAuth: needAuth
  }
});
app.children.push({
  path: '/job/:id',
  name: 'job',
  component: Job,
  props: true,
  meta: {
    needAuth: needAuth
  }
});
app.children.push({
  path: '/job/:jobID/checklist',
  name: 'form',
  component: Form,
  props: true,
  meta: {
    needAuth: needAuth
  }
});
routes.push(app);
routes.push({
  path: '*',
  name: 'notfound',
  redirect: '/login'
}); //{
//  path: '/about',
//  name: 'About',
//  // route level code-splitting
//  // this generates a separate chunk (about.[hash].js) for this route
//  // which is lazy-loaded when the route is visited.
//  component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
//}

var router = new VueRouter({
  routes: routes
});
router.beforeEach(function (to, from, next) {
  if (!authCheck(to, from)) return next("/login"); //if (to.path != '/video' && to.path != '/login') return next('/video')

  next();
});
export default router;