import router from '@/scripts/router';
import store from '@/scripts/store';
var SESSION_NEED_AUTH = true;
var SESSION_DATA = 'sessionTime';
var SESSION_LIMIT_TIME = 1000 * 60 * 60 * 12; // 12h

function storeUser() {
  var session = localStorage.getItem(SESSION_DATA);
  if (!session) return store.commit('user', null);
  var json = null;

  try {
    json = JSON.parse(session);
  } catch (_unused) {}

  if (!json || !json.user) {
    store.commit('user', null);
    return;
  }

  store.commit('user', json.user);
}

export var sessionTimedOut = function sessionTimedOut() {
  var session = localStorage.getItem(SESSION_DATA);
  var loginTime = 0;

  if (session) {
    loginTime = JSON.parse(session).iat;
  }

  loginTime = new Date().getTime() - loginTime;
  return loginTime > SESSION_LIMIT_TIME;
};
export var sessionRefresh = function sessionRefresh() {
  var session = localStorage.getItem(SESSION_DATA);
  if (!session) return;else session = JSON.parse(session);
  session.iat = new Date().getTime();
  localStorage.setItem(SESSION_DATA, JSON.stringify(session));
  storeUser();
};
export var sessionTerminate = function sessionTerminate() {
  var redirect = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : true;
  localStorage.removeItem(SESSION_DATA);
  storeUser();

  if (redirect) {
    setTimeout(function () {
      if (router) router.push("/login").catch(function () {});
    }, 100);
  }
};
export var sessionInit = function sessionInit(user) {
  var session = {};
  session.iat = new Date().getTime();
  session.user = user;
  localStorage.setItem(SESSION_DATA, JSON.stringify(session));
  storeUser();
};
export var sessionCheck = function sessionCheck() {
  var session = localStorage.getItem(SESSION_DATA);
  if (!session) return false;else if (sessionTimedOut()) {
    sessionTerminate(true);
    return false;
  }
  storeUser();
  return true;
};
export var authCheck = function authCheck(to, from, next) {
  if (!SESSION_NEED_AUTH) return true;
  var goNext = true;

  if (to.meta && to.meta.needAuth == true) {
    goNext = sessionCheck();
    if (goNext) sessionRefresh();
  }

  return goNext;
};
setInterval(function () {
  sessionCheck();
}, 1000 * 10);
sessionCheck();