import _createForOfIteratorHelper from "C:/jobs/trampa/trampa-pwa/node_modules/@babel/runtime/helpers/esm/createForOfIteratorHelper";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/web.dom-collections.iterator.js";

/* eslint-disable no-console */
import { register } from 'register-service-worker';

if (process.env.NODE_ENV === 'production') {
  //register(`${process.env.BASE_URL}service-worker.js`, {
  register("".concat(process.env.BASE_URL, "./service-worker.js"), {
    ready: function ready() {
      console.log('App is being served from cache by a service worker.\n' + 'For more details, visit https://goo.gl/AFskqB');
    },
    registered: function registered(reg) {
      console.log('Service worker has been registered.');
    },
    cached: function cached() {
      console.log('Content has been cached for offline use.');
    },
    updatefound: function updatefound() {
      console.log('New content is downloading.');
    },
    updated: function updated() {
      console.log('New content is available; please refresh.');
      setTimeout(function () {
        window.location.reload(true);
      }, 500);

      try {
        caches.keys().then(function (names) {
          var _iterator = _createForOfIteratorHelper(names),
              _step;

          try {
            for (_iterator.s(); !(_step = _iterator.n()).done;) {
              var name = _step.value;
              caches.delete(name);
            }
          } catch (err) {
            _iterator.e(err);
          } finally {
            _iterator.f();
          }
        });
      } catch (_unused) {}
    },
    offline: function offline() {
      console.log('No internet connection found. App is running in offline mode.');
    },
    error: function error(_error) {
      console.error('Error during service worker registration:', _error);
    }
  });
}