import _objectSpread from "C:/jobs/trampa/trampa-pwa/node_modules/@babel/runtime/helpers/esm/objectSpread2";
//
//
//
//
//
//
//
//
//
//
//
//
import { mapGetters } from 'vuex';
export default {
  computed: _objectSpread({}, mapGetters(['menu'])),
  methods: {
    onClickMenu: function onClickMenu(e) {
      e.stopPropagation();
      console.log("click in");
    },
    onClickOut: function onClickOut() {
      this.$store.commit("menu", null);
      console.log("click out");
    },
    onClickClose: function onClickClose() {
      this.$store.commit("menu", null);
    },
    onClickLogout: function onClickLogout() {
      this.$navigate('/login');
    }
  }
};