import Vue from 'vue';
import Vuex from 'vuex';
Vue.use(Vuex);
export default new Vuex.Store({
  state: {
    client: null,
    user: null,
    job: null,
    menu: null
  },
  getters: {
    client: function client(state, globalState) {
      return state.client;
    },
    user: function user(state, globalState) {
      return state.user;
    },
    job: function job(state, globalState) {
      return state.job;
    },
    menu: function menu(state, globalState) {
      return state.menu;
    }
  },
  mutations: {
    client: function client(state, value) {
      return state.client = value;
    },
    user: function user(state, value) {
      return state.user = value;
    },
    job: function job(state, value) {
      return state.job = value;
    },
    menu: function menu(state, value) {
      return state.menu = value;
    }
  },
  actions: {},
  modules: {}
});