import _objectSpread from "C:/jobs/trampa/trampa-pwa/node_modules/@babel/runtime/helpers/esm/objectSpread2";
import "core-js/modules/es.function.name.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { mapGetters } from 'vuex';
export default {
  computed: _objectSpread(_objectSpread({}, mapGetters(['user', 'job'])), {}, {
    userName: function userName() {
      return this.user.name;
    },
    document: function document() {
      return 'CPF: ' + this.user.cpf;
    },
    category: function category() {
      return this.user.category;
    },
    showUser: function showUser() {
      return this.$route.name == 'home';
    },
    showJob: function showJob() {
      return this.$route.name != 'home';
    },
    showBack: function showBack() {
      return this.$route.name != 'home';
    },
    jobDetails: function jobDetails() {
      if (!this.job) return {};
      console.log('job', this.job);
      return {
        room: this.job.room.name,
        unit: this.job.unit.name,
        date: this.job.date
      };
    }
  }),
  methods: {
    onClickMenu: function onClickMenu() {
      console.log("onClickUser");
      this.$store.commit("menu", true);
    },
    onClickBack: function onClickBack() {
      this.$navigate(-1);
    }
  }
};