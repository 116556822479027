import "C:\\jobs\\trampa\\trampa-pwa\\node_modules\\core-js\\modules\\es.array.iterator.js";
import "C:\\jobs\\trampa\\trampa-pwa\\node_modules\\core-js\\modules\\es.promise.js";
import "C:\\jobs\\trampa\\trampa-pwa\\node_modules\\core-js\\modules\\es.object.assign.js";
import "C:\\jobs\\trampa\\trampa-pwa\\node_modules\\core-js\\modules\\es.promise.finally.js";
import "core-js/modules/es.object.to-string.js";

/*
var s;
var password = "12345678";

while(true){
    if(s != password){
        s = prompt("Please enter password!");
    } else {
        break;
    }
}
*/
import Vue from 'vue';
import App from './App.vue';
import './registerServiceWorker';
import router from '@/scripts/router';
import store from '@/scripts/store';
import '@/scripts/api.js';
import '@/scripts/utils.js';
import '@/scripts/global-components.js';
import '@fortawesome/fontawesome-free/css/all.css';
import '@fortawesome/fontawesome-free/js/all.js';
import vuetify from './plugins/vuetify';
import pack from '../package.json';
import env from './scripts/env.js';
Vue.config.productionTip = false;
Vue.prototype.$package = pack;

Vue.prototype.$wait = function (ms) {
  return new Promise(function (res) {
    return setTimeout(res, ms);
  });
};

Vue.prototype.$navigate = function (path) {
  if (path == -1) {
    router.back();
    return;
  }

  router.push(path).catch(function (x) {});
};

Vue.prototype.$env = env;
new Vue({
  router: router,
  store: store,
  vuetify: vuetify,
  render: function render(h) {
    return h(App);
  }
}).$mount('#app');
/*
// register service worker
if ('serviceWorker' in navigator) {
  window.addEventListener('load', () => {
    navigator.serviceWorker.register('./sw.js')
    .then(reg => {
      console.log('Registrando nosso primeiro service worker', reg)
    })
    .catch(err => {
      console.log('Algo de errado aconteceu', err)
    })
  })
}
*/