import "core-js/modules/web.dom-collections.for-each.js";
import "core-js/modules/es.object.keys.js";
import "core-js/modules/es.array.slice.js";
//
//
//
//
//
//
//
export default {
  data: function data() {
    return {
      time: "00:00"
    };
  },
  mounted: function mounted() {
    var _this = this;

    this.updateTime();
    setInterval(function () {
      return _this.updateTime();
    }, 1000);
  },
  computed: {
    cssProps: function cssProps() {
      var _this2 = this;

      var themeColors = {};
      Object.keys(this.$vuetify.theme.themes.light).forEach(function (color) {
        themeColors["--v-".concat(color)] = _this2.$vuetify.theme.themes.light[color];
      });
      return themeColors;
    },
    version: function version() {
      return this.$package.version;
    }
  },
  methods: {
    updateTime: function updateTime() {
      var cd = new Date();
      this.time = this.zeroPadding(cd.getHours(), 2) + ':' + this.zeroPadding(cd.getMinutes(), 2);
    },
    zeroPadding: function zeroPadding(num, digit) {
      var zero = '';

      for (var i = 0; i < digit; i++) {
        zero += '0';
      }

      return (zero + num).slice(-digit);
    }
  }
};