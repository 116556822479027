import Events from './events'
import axios from 'axios'
import env from './env'
import store from '@/scripts/store'

let ENDPOINT = "https://api.trampa.dev.br" //env.VUE_APP_API_ENDPOINT

let hostname = window.location.hostname

if (hostname === 'localhost' || hostname === '127.0.0.1') {
    ENDPOINT = "http://localhost:3000"
    console.log('api:', ENDPOINT)
}
else if (hostname === 'staging.trampa.com.br') {
    ENDPOINT = "http://trampa-api-development.us-east-1.elasticbeanstalk.com"
    console.log('api:', ENDPOINT)
}

class REST extends Events {

    headers = {}
    constructor() { super() }

    composeURL(method) {
        if (method.length == 0) return ENDPOINT
        return ENDPOINT + method
    }

    async get(method) { return await this.request(this.composeURL(method), 'get') }
    async post(method, body) { return await this.request(this.composeURL(method), 'post', body) }
    async put(method, body) { return await this.request(this.composeURL(method), 'put', body) }
    async del(method) { return await this.request(this.composeURL(method), 'delete') }

    getHeaders() {
        let obj = {'Content-Type': `application/json`}
        if (store.getters.user) obj["Authorization"] = `Bearer ${store.getters.user.token}`
        return obj
    }

    async request(url, method = 'get', body = null) {
        return new Promise((res, rej) => {
            if (method == 'get' && body) {
                let count = 0
                for (const [key, value] of Object.entries(body)) {
                    url += (count == 0 ? '?' : '&') + key + '=' + value
                    count++
                }
            }
            
            let axiosConfig = { headers: this.getHeaders(), method, url, data: body }
            
            axios(axiosConfig).then(axiosRes => res(axiosRes.data)).catch(axiosrRej => {
                let error = axiosrRej.message
                if (axiosrRej.config 
                    && axiosrRej.response 
                    && axiosrRej.response.data 
                    && axiosrRej.response.data.error
                    && axiosrRej.response.data.error.message)
                {
                    error = axiosrRej.response.data.error.message
                }
                res({ error })
            })
        });
    }

    async upload(file, extension) {
        const awsData = await fetch(`${ENDPOINT}/file/upload-url?extension=${extension}`).then(response => response.json())
        console.log("awsData", awsData)
        const {url, fields} = awsData
        const data = { ...fields, file }
        const formData = new FormData()
        for (const name in data) { formData.append(name, data[name]) }
        const response = await fetch(url, { method: 'Post', body: formData })
        console.log('s3 upload response', response)
        console.log(response.status)
        return { file: fields.key }
    }
}

export default new REST()
