import _objectSpread from "C:/jobs/trampa/trampa-pwa/node_modules/@babel/runtime/helpers/esm/objectSpread2";
//
//
//
//
//
//
import { mapGetters } from 'vuex';
import VideoRecord from '../../components/VideoRecord.vue';
export default {
  components: {
    VideoRecord: VideoRecord
  },
  mounted: function mounted() {
    if (!this.user) {
      this.$navigate('/login');
    }
  },
  computed: _objectSpread({}, mapGetters(['user']))
};